<template>
  <div>
    <s-search :queryItems="queryItems" @fetchData="fetchListSuspend"></s-search>

    <div class="table-button">
      <a-button
        type="plain"
        icon="pause-circle"
        @click="handleSuspend('suspend')"
        :disabled="selectedRowKeys.length === 0"
        >暂停施工</a-button
      >
      <a-button
        type="plain"
        icon="play-circle"
        @click="handleSuspend('recovery')"
        :disabled="selectedRowKeys.length === 0"
        >恢复施工</a-button
      >
      <a-button type="plain" icon="cloud-download" @click="handleExport"
        >导出</a-button
      >
      <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
        >刷新</a-button
      >
      <columns-set
        tableName="SiteSuspend"
        :columns="columns"
        @changeColumns="changeColumns"
      ></columns-set>
    </div>

    <s-table
      ref="table"
      size="default"
      rowKey="ws_id"
      :rowSelection="rowSelection"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: 1000 }"
    >
      <span slot="ws_name" slot-scope="text">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <span>{{ text }}</span>
          </template>
          <span>{{ text }}</span>
        </a-tooltip>
      </span>

      <span slot="serial" slot-scope="text, record, index">
        {{ (pageNo - 1) * pageSize + index + 1 }}
      </span>
      <span slot="ws_bind_status" slot-scope="text">
        <span class="status1" v-show="text === '1'">{{
          text | FBindStatus
        }}</span>
        <span v-show="text === '2'">{{ text | FBindStatus }}</span>
      </span>
    </s-table>

    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="suspendOk"
      @cancel="suspendCancel"
    >
      <p>
        {{
          "确定" + (handleType === "suspend" ? "暂停施工" : "恢复施工") + "？"
        }}
      </p>
    </a-modal>
    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visibleExport"
      :confirm-loading="confirmExportLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>确定导出？</p>
    </a-modal>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { GetServiceList, StopWork, RecoveryWork } from "@/api/site";
import { ExportStopped } from "@/api/export";

// 搜索条件
const queryItems = [
  {
    label: "分公司名称",
    value: "bc_name",
    type: "select",
    selectOption: "CompanyList",
  },
  { label: "项目经理", value: "w_name", type: "input" },
  { label: "工地名称", value: "ws_name", type: "input" },
];

const columns = [
  {
    title: "序号",
    scopedSlots: { customRender: "serial" },
    width: "70px",
    dataIndex: "serial",
    align: "center",
  },
  {
    title: "分公司名称",
    width: "110px",
    dataIndex: "bc_name",
    align: "center",
  },
  {
    title: "项目名称",
    width: "300px",
    dataIndex: "ws_name",
    scopedSlots: { customRender: "ws_name" },
    ellipsis: true,
  },
  {
    title: "工程管家",
    width: "90px",
    dataIndex: "w_name_gj",
    align: "center",
  },
  {
    title: "项目经理",
    width: "90px",
    dataIndex: "w_name_jl",
    align: "center",
  },
  {
    title: "绑定状态",
    width: "90px",
    dataIndex: "ws_bind_status",
    scopedSlots: { customRender: "ws_bind_status" },
    align: "center",
  },
];
const orderParam = [
  "ws_name",
  "w_name",
  "w_gj_name",
  "bc_name",
  "nowork",
  "ws_bind_time_start",
  "ws_bind_time_end",
  "ws_bind_status",
  "ws_status",
  "ws_service_type",
  "ws_attendance",
  "ws_only",
  "d_status",
  "ws_dispatch",
  "bc_id",
  "cs_id",
  "d_imei",
  "pageNo",
  "pageSize",
];

export default {
  name: "SiteList",
  components: {
    STable,
    SSearch,
    ColumnsSet,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      handleType: "",
      visible: false,
      confirmLoading: false,
      visibleExport: false,
      confirmExportLoading: false,
      // 查询参数
      queryParam: {},
      selectedRowKeys: [],
      selectedRows: [],
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
    };
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
      };
    },
  },
  created() {
    this.tableColumns = columns;
    this.changeColumns();
  },
  methods: {
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("SiteSuspendColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      this.pageNo = parameter.pageNo;
      this.pageSize = parameter.pageSize;
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        ws_status: "",
        d_status: "",
        ws_bind_status: "",
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetServiceList(params).then((res) => {
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;
        return res.data;
      });
    },
    fetchListSuspend(params) {
      this.queryParam = params;
      this.$refs.table.refresh(true);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    handleSuspend(type) {
      this.visible = true;
      this.handleType = type;
    },
    suspendOk() {
      this.confirmLoading = true;
      const ids = this.selectedRows.map((el) => {
        return el.ws_id;
      });
      const params = {
        ws_ids: ids.toString(),
      };
      if (this.handleType === "suspend") {
        StopWork(params).then(() => {
          this.visible = false;
          this.confirmLoading = false;
          // 刷新表格
          this.$refs.table.refresh();
        });
      } else if (this.handleType === "recovery") {
        RecoveryWork(params).then(() => {
          this.visible = false;
          this.confirmLoading = false;
          // 刷新表格
          this.$refs.table.refresh();
        });
      }
    },
    suspendCancel() {
      this.visible = false;
    },
    handleExport() {
      this.visibleExport = true;
    },
    exportOk() {
      this.confirmExportLoading = true;
      const params = {
        ws_ids: this.selectedRows.map((el) => {
          return el.ws_id;
        }),
      };
      ExportStopped(params).then(() => {
        this.visibleExport = false;
        this.confirmExportLoading = false;
      });
    },
    exportCancel() {
      this.visibleExport = false;
    },
  },
};
</script>
<style lang="less" scoped>
.status1 {
  color: #96959d;
} // 表头居中显示
/deep/.ant-table-thead > tr > th {
  text-align: center;
}
</style>
